const GET_RESERVATION = 'autohost/guestportal/GET_RESERVATION';
const GET_RESERVATION_SUCCESS = 'autohost/guestportal/GET_RESERVATION_SUCCESS';
const GET_RESERVATION_FAIL = 'autohost/guestportal/GET_RESERVATION_FAIL';
const SET_OVERLAY_FORM = 'autohost/guestportal/SET_OVERLAY_FORM';
const SET_USERBACK_IS_LOADED = 'autohost/guestportal/SET_USERBACK_IS_LOADED';

const initialState = {
    loading: false,
    loaded: false,
    reservation: null,
    checkin: null,
    error: null,
    overlay: null,
    userbackIsLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_RESERVATION:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_RESERVATION_SUCCESS:
            // // always update (even if score goes down)
            // return {
            //     ...state,
            //     loading: false,
            //     loaded: true,
            //     reservation: action.payload
            // }
            // Only update if score goes up or safe status changes
            const oldPendingStatus = state?.reservation?.is_pending;
            const newPendingStatus = action?.payload?.is_pending;
            const newScore = action.payload.protect_score;
            const oldScore = state.reservation && state.reservation.protect_score;
            if (!oldScore || (oldScore && newScore && oldScore < newScore) || oldPendingStatus !== newPendingStatus) {
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    // reservation: action.payload,
                    reservation: {
                        ...action.payload,

                        // // Do not change the risk color during user session (unless they refresh the page)
                        // protect_color: state?.reservation?.protect_color || action.payload.protect_color,
                    },
                    error: null,
                };
            }
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null,
            };
        case GET_RESERVATION_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
            };
        case SET_OVERLAY_FORM:
            return {
                ...state,
                overlay: action.overlay,
            };
        case SET_USERBACK_IS_LOADED: {
            return {
                ...state,
                userbackIsLoaded: action.isLoaded,
            };
        }
        default:
            return state;
    }
}

export function getReservation(id, options = {}) {
    const query = Object.keys(options)
        .map((k) => `${k}=${options[k]}`)
        .join('&');
    return {
        types: [GET_RESERVATION, GET_RESERVATION_SUCCESS, GET_RESERVATION_FAIL],
        id: id,
        promise: (client) => client.get(`/guestportal/reservation/${id}?${query}`),
    };
}

export function setOverlayForm(overlay) {
    return {
        type: SET_OVERLAY_FORM,
        overlay,
    };
}

export function setUserbackIsLoaded(isLoaded) {
    return {
        type: SET_USERBACK_IS_LOADED,
        isLoaded,
    };
}
